import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import { bouncy } from 'ldrs';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init({
  dsn: "https://8561c8451279dd4d4aa48a49e0a0be16@o4507307752488960.ingest.de.sentry.io/4508098648146000",
  tracesSampleRate: 1,

  integrations: [Sentry.browserTracingIntegration({
    useEffect,
    useLocation,
    useMatches
  }), Sentry.replayIntegration({
    maskAllText: true,
    blockAllMedia: true
  })],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: process.env.REACT_APP_ENV
})

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    // Add v={} to cache-bust the service worker, ensuring the latest version is always used
    navigator.serviceWorker.register(`/service-worker.js?v=${Date.now()}`);
  });
}

bouncy.register();

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});